import { HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { API_PATH_APPS } from 'src/app/core/constants';

export const ApiConfigurations: IHowdenApiInfo[] = [{
  name: API_PATH_APPS.API_RISK_MAP,
  paths: {
    local: 'https://localhost:44360/',
    develop: 'https://how-apiriskmap.app-dev.howdeniberia.com/',
    staging: 'https://how-apiriskmap.app-staging.howdeniberia.com/',
    production: 'https://how-apiriskmap.app.howdeniberia.com/',
    devHowdenGroup: 'https://how-apiriskmap.app-dev.howdeniberia.com/',
    preHowdenGroup: 'https://how-apiriskmap.app-staging.howdeniberia.com/',
    proHowdenGroup: 'https://how-apiriskmap.app.howdeniberia.com/'
  },
  useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
  needsToken: true
}] as IHowdenApiInfo[];

import { EChartsOption } from 'echarts';
import { IMap, IRiskRating } from '../../models/api';
import { IMapRiskInfo } from '../../models/front';

export abstract class IRisksDataMapService {
  abstract setMapId(val: number): void;
  abstract setAlreadySaved(val: boolean): void;
  abstract canSaveMap(): boolean;
  abstract getDataRiskMapEmitter(): any;
  abstract getDataStepEmitter(): any;
  abstract getDataRiskMap(mapDataReport: IMap | null): IMap;
  abstract getRiskRatings(categoryId: number | null): Array<IRiskRating>;
  abstract loadDataRiskMap(dataMap: IMap): void;
  abstract updateDataRiskMap(risk: IRiskRating): void;
  abstract deleteDataRiskMap(riskId: number): void;
  abstract addDiscardedRisk(risk: IRiskRating): void;
  abstract deleteDiscardedRisk(risk: IRiskRating): void;
  abstract getChartOptions(data: Array<IMapRiskInfo>): EChartsOption;
}

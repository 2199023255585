import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IButtonActionData, IPageOf, ITableEventData } from '@howdeniberia/core-front';
import { IMap } from 'src/app/core/models/api';
import { IMapService } from 'src/app/core/services/api/map';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { MapMapper } from '../../mapper';
import { MapSearchViewModel } from '../../models';

@Component({
  selector: 'howden-map-search',
  templateUrl: './map-search.component.html',
  styleUrl: './map-search.component.scss'
})
export class MapSearchComponent implements OnInit, OnDestroy {
  model = new MapSearchViewModel();

  get minDate(): Date { return Limits.minDate; }

  private _subscriptions = new SubSink();

  constructor(
    private router: Router,
    private mapSrv: IMapService,
    private uiBlockerSrv: IUIBlockerService
  ) { }

  ngOnInit(): void {
    this.refresh();
    this.onChanges();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'edit') {
      this.onEdit(event);
    } else if (event.buttonName === 'download') {
      this.onDownload(event);
    }
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    this.model.updateServerSideFilters();

    this.uiBlockerSrv.block();

    this.mapSrv.search(this.model.searchRequest).subscribe({
      next: (searchResult: IPageOf<IMap>) => {
        this.model.length = searchResult.totalCount;
        this.model.data = searchResult.data?.map((source) =>
          MapMapper.mapForSearch(source)
        );
      },
      complete: () => {
        this.uiBlockerSrv.unblock();
      }
    });
  }

  private onEdit(event: IButtonActionData) {
    this.router.navigate([`management/risks-evaluation/${event.row.mapId}`]);
  }

  private onDownload(event: IButtonActionData) {
    this.uiBlockerSrv.block();

    const mapId = event.row.mapId;

    this.mapSrv.downloadReport(mapId).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename('RiskMap', 'pdf');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }
}

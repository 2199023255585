import { FormControl, FormGroup } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableEventData
} from '@howdeniberia/core-front';
import { ICategory, IMapRatingFilter } from 'src/app/core/models/api';
import { IRiskRatingViewModel } from '.';


export class RiskRatingSearchViewModel {
  private _filterForm: FormGroup;
  private _categories: Array<ICategory> = [];
  private _searchRequest: IMapRatingFilter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_DATE_FROM = 'dateFrom';
  readonly C_DATE_TO = 'dateTo';
  readonly C_CATEGORY_ID = 'categoryId';
  readonly C_DESCRIPTION = 'description';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_DATE_FROM]: new FormControl(null, []),
      [this.C_DATE_TO]: new FormControl(null, []),
      [this.C_CATEGORY_ID]: new FormControl(null, []),
      [this.C_DESCRIPTION]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IMapRatingFilter;
  }

  data: IRiskRatingViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'mapDescription',
      fieldHeader: $localize`:@@app.risk-rating.search.mapDescription.column:Mapa`,
      width: '12%'
    },
    {
      fieldName: 'mapUserCreator',
      fieldHeader: $localize`:@@app.risk-rating.search.mapUserCreator.column:Creador`,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'mapDateCreator',
      fieldHeader: $localize`:@@app.risk-rating.search.mapDateCreator.column:F. Creación`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      width: '10%'
    },
    {
      fieldName: 'categoryDesc',
      fieldHeader: $localize`:@@app.risk-rating.search.categoryDesc.column:Categoría`,
      canSort: true,
      width: '10%'
    },
    {
      fieldName: 'riskId',
      fieldHeader: $localize`:@@app.risk-rating.search.riskId.column:Id Riesgo`,
      canSort: true,
      aligment: ColumnAligment.CENTER,
      width: '8%'
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.risk-rating.search.description.column:Riesgo`,
      canSort: true,
      width: '20%'
    },
    {
      fieldName: 'probability',
      fieldHeader: $localize`:@@app.risk-rating.search.probability.column:Probabilidad`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT,
      width: '8%'
    },
    {
      fieldName: 'impact',
      fieldHeader: $localize`:@@app.risk-rating.search.impact.column:Impacto`,
      canSort: true,
      pipeToApply: ColumnPipeTypes.DECIMAL,
      aligment: ColumnAligment.RIGHT,
      width: '8%'
    },
    {
      fieldName: 'discarded',
      fieldHeader: $localize`:@@app.risk-rating.search.discarded.column:Descartado`,
      canSort: false,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.CENTER,
      width: '8%'
    }
  ];

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get categories(): Array<ICategory> {
    return this._categories;
  }

  set categories(value: Array<ICategory>) {
    this._categories = value;
  }

  get searchRequest(): IMapRatingFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get dateFrom(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_FROM)?.value;
  }
  set dateFrom(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_FROM)?.setValue(value);
  }

  get dateTo(): Date | null | undefined {
    return this._filterForm.get(this.C_DATE_TO)?.value;
  }
  set dateTo(value: Date | null | undefined) {
    this._filterForm.get(this.C_DATE_TO)?.setValue(value);
  }

  get categoryId(): number | null | undefined {
    return this._filterForm.get(this.C_CATEGORY_ID)?.value;
  }
  set categoryId(value: number | null | undefined) {
    this._filterForm.get(this.C_CATEGORY_ID)?.setValue(value);
  }

  get description(): string | null | undefined {
    return this._filterForm.get(this.C_DESCRIPTION)?.value;
  }
  set description(value: string | null | undefined) {
    this._filterForm.get(this.C_DESCRIPTION)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.dateFrom = this.dateFrom;
    this.searchRequest.dateTo = this.dateTo;
    this.searchRequest.categoryId = this.categoryId;
    this.searchRequest.description = this.description;
  }
}

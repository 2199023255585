import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RisksEvaluationComponent } from './pages/risks-evaluation/risks-evaluation.component';

export const RiskMapEvaluationRoutes = [
  {
    path: 'risks-evaluation',
    component: RisksEvaluationComponent,
    title: $localize`:@@app.route.management.risks-evaluation: Evaluar Riesgos`,
    data: {}
  },
  {
    path: 'risks-evaluation/:id',
    component: RisksEvaluationComponent,
    title: $localize`:@@app.route.management.risks-evaluation: Evaluación Riesgos`,
    data: {}
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(RiskMapEvaluationRoutes)],
  exports: [RouterModule]
})
export class RiskMapEvaluationRoutingModule { }

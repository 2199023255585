import { EventEmitter, Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { IRisksDataMapService } from '.';
import { IMap, IRiskRating } from '../../models/api';
import { IMapRiskInfo, ISymbolStyleRiskMap } from '../../models/front';
import { ICategoryService } from '../api/category';

@Injectable({
  providedIn: 'root'
})
export class RisksDataMapService implements IRisksDataMapService {
  mapDto: IMap = {} as IMap;
  dataRiskMap: Array<IMapRiskInfo> = [];
  listRisksDiscarded: Array<IRiskRating> = [];
  alreadySaved = false;

  dataChange: EventEmitter<Array<IMapRiskInfo>> = new EventEmitter();
  stepChange: EventEmitter<Array<IRiskRating>> = new EventEmitter();
  stylesDataRiskMapType = new Array<ISymbolStyleRiskMap>();

  numRegLine = 4;
  listSymbols: string[] = ['circle', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'];
  listColors: string[] = ['#173F35', '#3C0F11', '#0857C3', '#998542', '#7CE0D3', '#E0EC89'];
  symbolsStyles = [
    { symbol: 'rect', size: 20, labelPos: 'middle' },
    { symbol: 'circle', size: 25, labelPos: 'middle' },
    { symbol: 'roundRect', size: 25, labelPos: 'middle' },
    { symbol: 'triangle', size: 30, labelPos: 'top' },
    { symbol: 'diamond', size: 30, labelPos: 'middle' },
    { symbol: 'pin', size: 38, labelPos: 'middle' },
    { symbol: 'arrow', size: 25, labelPos: 'middle' }
  ];

  green = '#0DA20D';
  yellow = '#FFCC00';
  orange = '#FAAA00';
  red = '#DC000F';
  grey = '#8A8A8A';

  constructor(
    private categorySrv: ICategoryService
  ) {
    // Cargamos estilos
    this.stylesDataRiskMapType.push({
      // Para cuando coinciden varias categorías
      category: 99,
      symbol: 'rect',
      color: '#3D3D3D'
    });

    let indexSimbol = 0;
    let indexColor = 0;
    let longArray = 0;

    // Establecemos los estilos de los símbolos
    this.categorySrv.getCategories().subscribe((data) => {
      data.forEach(categ => {
        this.stylesDataRiskMapType.push({
          category: categ.categoryId,
          symbol: this.listSymbols[indexSimbol],
          color: this.listColors[indexColor]
        });

        longArray++;
        if (longArray < this.listSymbols.length) { // Sólo cambiamos símbolo
          indexSimbol++;
        } else if (longArray > this.listSymbols.length * this.listColors.length) { // Reiniciamos contadores
          indexSimbol = 0;
          indexColor = 0;
          longArray = 0;
        } else {
          indexSimbol = 0;
          longArray = 0;
          indexColor++;
        }
      });
    });
  }

  setMapId(val: number) {
    this.mapDto.mapId = val;
  }

  setAlreadySaved(val: boolean) {
    this.alreadySaved = val;
  }

  canSaveMap(): boolean {
    if (this.alreadySaved === true) {
      return false;
    } else if (this.listRisksDiscarded.length === 0 && this.dataRiskMap.length === 0) {
      return false;
    }
    return true;
  }

  getDataRiskMapEmitter() {
    return this.dataChange;
  }

  getDataStepEmitter() {
    return this.stepChange;
  }

  getDataRiskMap(mapDataReport: IMap | null): IMap {
    if (mapDataReport === null) {
      this.mapDto.onlySave = true;
    } else {
      this.mapDto.fiscalCode = mapDataReport.fiscalCode;
      this.mapDto.businessName = mapDataReport.businessName;
      this.mapDto.contactEmail = mapDataReport.contactEmail;
      this.mapDto.contactPhone = mapDataReport.contactPhone;
      this.mapDto.localityId = mapDataReport.localityId;
      this.mapDto.mapImageBase64 = mapDataReport.mapImageBase64;
      this.mapDto.onlySave = false;
    }

    this.mapDto.riskRatings = this.getRiskRatings(null);

    return this.mapDto;
  }

  getRiskRatings(categoryId: number | null): Array<IRiskRating> {
    const riskRatings: Array<IRiskRating> = [];

    //Incluimos las valoraciones de los riesgos
    let riskRating: IRiskRating;
    this.dataRiskMap.forEach(x => {
      x.risks.forEach(r => {
        if (categoryId === null || categoryId === r.categoryId) {
          riskRating = {
            riskId: r.riskId,
            description: r.description,
            example: r.example,
            originId: r.originId,
            categoryId: r.categoryId,
            categoryDesc: r.categoryDesc,
            discarded: false,
            probability: x.probability,
            impact: x.impact,
            color: x.color,
            imgCircle: x.imgCircle,
            order: x.order
          } as IRiskRating;

          riskRatings.push(riskRating);
        }
      });
    });

    //Incluimos los riesgos descartados
    if (categoryId === null) {
      this.listRisksDiscarded.forEach(x => {
        riskRatings.push(x);
      });
    } else {
      this.listRisksDiscarded.filter(x => x.categoryId === categoryId).forEach(x => {
        riskRatings.push(x);
      });
    }

    return riskRatings;
  }

  loadDataRiskMap(dataMap: IMap) {
    this.mapDto = dataMap;
    this.listRisksDiscarded = [];
    this.dataRiskMap = [];

    dataMap.riskRatings.filter(x => x.discarded === true).forEach(risk => {
      this.addDiscardedRisk(risk);
    });

    dataMap.riskRatings.filter(x => x.discarded === false).forEach(risk => {
      this.updateDataRiskMap(risk);
    });

    this.dataChange.emit(this.dataRiskMap);
    this.stepChange.emit(dataMap.riskRatings);
  }

  updateDataRiskMap(risk: IRiskRating) {
    this.checkDataRiskMap(risk, risk.probability ?? 0, risk.impact ?? 0);
  }

  deleteDataRiskMap(riskId: number) {
    this.deleteRisk(riskId);
    this.alreadySaved = false;
    this.dataChange.emit(this.dataRiskMap);
  }

  addDiscardedRisk(risk: IRiskRating) {
    risk.discarded = true;
    this.alreadySaved = false;
    this.listRisksDiscarded.push(risk);
  }

  deleteDiscardedRisk(risk: IRiskRating) {
    if (this.listRisksDiscarded.filter(x => x.riskId === risk.riskId).length > 0) {
      this.alreadySaved = false;
      const riskToDelete = this.listRisksDiscarded.filter(x => x.riskId === risk.riskId)[0];
      this.listRisksDiscarded.splice(this.listRisksDiscarded.indexOf(riskToDelete), 1);
    }
  }

  getChartOptions(data: Array<IMapRiskInfo>): EChartsOption {
    const dataMap = new Array<any>();
    // let chartOptions: EChartOption | null = null;
    // TODO: Review
    let chartOptions: any = null;

    data.forEach((x) => dataMap.push({
      name: x.code,
      value: [x.impact, x.probability],
      symbol: x.symbol,
      symbolSize: [x.symbWidth, x.symbHigh],
      itemStyle: { color: x.symbColor },
      emphasis: {
        label: {
          show: true,
          formatter: function () {
            return x.label;
          },
          position: 'top',
          fontWeight: 'bold',
          color: '#000000'
        }
      },
      label: {
        show: true,
        position: 'inside',
        verticalAlign: x.symbPosLabel,
        formatter: (params: any) => params.data.name
      }
    }));

    chartOptions = {
      series: {
        data: dataMap,
        type: 'scatter'
      },
      grid: {
        height: '80%',
        width: '80%',
        show: false
      },
      xAxis: {
        name: 'Impacto',
        nameLocation: 'center',
        nameTextStyle: {
          color: '#173F35',
          fontWeight: 'bold',
          fontSize: '18'
        },
        show: true,
        type: 'value',
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: '6',
            color: 'white'
          }
        }
      },
      yAxis: {
        name: 'Probabilidad',
        nameLocation: 'center',
        nameTextStyle: {
          color: '#173F35',
          fontWeight: 'bold',
          fontSize: '18'
        },
        show: true,
        type: 'value',
        min: 0,
        max: 5,
        splitNumber: 5,
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            width: '6',
            color: 'white'
          }
        }
      }
    };

    return chartOptions;
  }

  private checkDataRiskMap(risk: IRiskRating, probability: number, impact: number) {
    const riskItem: IMapRiskInfo | undefined = this.dataRiskMap.find(x => x.risks.find(r => r.riskId === risk.riskId));
    const dataItem: IMapRiskInfo | undefined = this.dataRiskMap.find(x => x.probability === probability && x.impact === impact);

    if (!riskItem && !dataItem) {
      this.addNewRisk(risk, probability, impact);
    } else {
      if (riskItem) {
        this.deleteRisk(risk.riskId);
      }
      if (dataItem) {
        this.addNewRiskToData(dataItem, risk);
      } else {
        this.addNewRisk(risk, probability, impact);
      }
    }

    this.alreadySaved = false;
    this.dataChange.emit(this.dataRiskMap);
  }

  // Añadimos nuevo punto al gráfico
  private addNewRisk(risk: IRiskRating, probability: number, impact: number) {
    const style: ISymbolStyleRiskMap | undefined = this.stylesDataRiskMapType.find(x => x.category === risk.categoryId);
    const color = this.calculateColor(probability, impact);

    let orden = probability * impact;
    let imgCirculo = 'grey-circle.png';

    switch (color) {
      case (this.red):
        orden = orden + 400;
        imgCirculo = 'red-circle.png';
        break;
      case (this.orange):
        orden = orden + 300;
        imgCirculo = 'orange-circle.png';
        break;
      case (this.yellow):
        orden = orden + 200;
        imgCirculo = 'yellow-circle.png';
        break;
      case (this.green):
        orden = orden + 100;
        imgCirculo = 'green-circle.png';
        break;
    }

    this.dataRiskMap.push({
      risks: [risk],
      code: this.getCodeDataRiskMap([risk]),
      label: this.getLabelDataRiskMap([risk]),
      probability: probability,
      impact: impact,
      color: color,
      imgCircle: imgCirculo,
      order: orden,
      symbol: style?.symbol ?? '',
      symbHigh: this.symbolsStyles.find(x => x.symbol === style?.symbol)?.size ?? 0,
      symbWidth: this.symbolsStyles.find(x => x.symbol === style?.symbol)?.size ?? 0,
      symbPosLabel: this.symbolsStyles.find(x => x.symbol === style?.symbol)?.labelPos ?? '',
      symbColor: style?.color ?? ''
    });
  }

  // Añadir nuevo risk a un punto del gráfico
  private addNewRiskToData(dataItem: IMapRiskInfo, risk: IRiskRating) {
    const indexData = this.dataRiskMap.indexOf(dataItem);

    // Añadimos risk a los datos de probabilidad e impacto
    dataItem.risks.push(risk);
    dataItem.code = this.getCodeDataRiskMap(dataItem.risks);
    dataItem.label = this.getLabelDataRiskMap(dataItem.risks);

    const style: ISymbolStyleRiskMap | undefined = this.stylesDataRiskMapType.find(x => x.category === 99);

    if (style) {
      dataItem.symbol = style?.symbol;
      dataItem.symbHigh = this.calculateMultiPointHigh(dataItem.risks.length, style.symbol);
      dataItem.symbWidth = this.calculateMultiPointWidth(dataItem.risks.length, style.symbol);
      dataItem.symbPosLabel = this.symbolsStyles?.find(x => x.symbol === style.symbol)?.labelPos ?? '';
      dataItem.symbColor = style.color;
    }

    this.dataRiskMap[indexData] = dataItem;
  }

  private deleteRisk(riskId: number) {
    const riskItem = this.dataRiskMap.find(x => x.risks.find(r => r.riskId === riskId));
    if (riskItem) {
      const indexRisk = this.dataRiskMap.indexOf(riskItem);

      if (riskItem.risks.length === 1) { // Sólo hay este => hay que eliminar todo el registro
        this.dataRiskMap.splice(this.dataRiskMap.indexOf(riskItem), 1);
      } else {
        const riskEliminar: IRiskRating | undefined = riskItem.risks.find(r => r.riskId === riskId);

        if (riskEliminar) {
          riskItem.risks.splice(riskItem.risks.indexOf(riskEliminar), 1); // Eliminamos el risk
        }

        riskItem.code = this.getCodeDataRiskMap(riskItem.risks);
        riskItem.label = this.getLabelDataRiskMap(riskItem.risks);

        if (riskItem.risks.length === 1) { // Recuperamos el símbolo que le corresponde
          const style: ISymbolStyleRiskMap | undefined = this.stylesDataRiskMapType.find(x => x.category === riskItem.risks[0].categoryId);

          if (style) {
            riskItem.symbol = style.symbol;
            riskItem.symbHigh = this.symbolsStyles?.find(x => x.symbol === style.symbol)?.size ?? 0;
            riskItem.symbWidth = riskItem.symbHigh;
            riskItem.symbPosLabel = this.symbolsStyles?.find(x => x.symbol === style.symbol)?.labelPos ?? '';
            riskItem.symbColor = style.color;
          }
        } else {
          riskItem.symbHigh = this.calculateMultiPointHigh(riskItem.risks.length, riskItem.symbol);
          riskItem.symbWidth = this.calculateMultiPointWidth(riskItem.risks.length, riskItem.symbol);
        }
        this.dataRiskMap[indexRisk] = riskItem;
      }
    }
  }

  private calculateMultiPointWidth(numReg: number, symbol: string): number {
    const size = this.symbolsStyles?.find(x => x.symbol === symbol)?.size;
    if (size) {
      if (numReg < this.numRegLine) {
        return numReg * size;
      }
      return this.numRegLine * size;
    }
    return 0;
  }

  private calculateMultiPointHigh(numReg: number, symbol: string): number {
    const size = this.symbolsStyles?.find(x => x.symbol === symbol)?.size;
    if (size) {
      if (numReg < this.numRegLine) {
        return size;
      }
      return size * (Math.floor(numReg / this.numRegLine) + (numReg % this.numRegLine === 0 ? 0 : 1));
    }
    return 0;
  }

  private getCodeDataRiskMap(risks: Array<IRiskRating>): string {
    let code = '';
    let numReg = 0;

    risks.forEach(r => {
      numReg++;
      if (numReg > this.numRegLine) {
        code += '\r\n';
        numReg = 1;
      } else if (code.length > 0) {
        code += '-';
      }
      code += r.riskId.toString();
    });

    return code;
  }

  private getLabelDataRiskMap(risks: Array<IRiskRating>): string {
    let label = '';

    risks.forEach(r => {
      if (label.length > 0) {
        label += '\r\n';
      }
      label += `${r.riskId.toString()}-${r.categoryDesc}`;
    });

    return label;
  }

  private calculateColor(probability: number | null, impact: number | null): string {
    if (typeof probability === 'undefined' || probability === null
      || typeof impact === 'undefined' || impact === null) {
      return this.grey;
    }

    if (probability <= 1) {
      if (impact <= 3) {
        return this.green;
      } else if (impact && impact <= 5) {
        return this.yellow;
      }
      return this.grey;
    } else if (probability <= 2) {
      if (impact <= 2) {
        return this.green;
      } else if (impact <= 4) {
        return this.yellow;
      } else if (impact <= 5) {
        return this.orange;
      }
      return this.grey;
    } else if (probability <= 3) {
      if (impact <= 1) {
        return this.green;
      } else if (impact <= 3) {
        return this.yellow;
      } else if (impact <= 4) {
        return this.orange;
      } else if (impact <= 5) {
        return this.red;
      }
      return this.grey;
    } else if (probability <= 4) {
      if (impact <= 2) {
        return this.yellow;
      } else if (impact <= 3) {
        return this.orange;
      } else if (impact <= 5) {
        return this.red;
      }
      return this.grey;
    } else if (probability <= 5) {
      if (impact <= 1) {
        return this.yellow;
      } else if (impact <= 2) {
        return this.orange;
      } else if (impact <= 5) {
        return this.red;
      }
      return this.grey;
    }
    return this.grey;
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IRisksDataMapService } from 'src/app/core/services/core';
import { IUIBlockerService } from 'src/app/core/services/ui';
import { DownloadReportComponent } from '../../dialogs/pages/download-report/download-report.component';

import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { IMapService } from 'src/app/core/services/api/map';
import { HelpRiskMapComponent } from 'src/app/features/help/dialogs/help-risk-map/help-risk-map.component';
import { SubSink } from 'subsink';
import { RisksStepsComponent } from '../risks-steps/risks-steps.component';

@Component({
  selector: 'howden-risks-evaluation',
  templateUrl: './risks-evaluation.component.html',
  styleUrls: ['./risks-evaluation.component.scss']
})
export class RisksEvaluationComponent implements OnInit, OnDestroy {
  @ViewChild(RisksStepsComponent, { static: true }) childSteps!: RisksStepsComponent;

  private _subscriptions = new SubSink();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private dataRiskMapSrv: IRisksDataMapService,
    private uiBlockerSrv: IUIBlockerService,
    private mapSrv: IMapService
  ) {
  }

  ngOnInit() {
    this._subscriptions.sink = this.activatedRoute.paramMap.pipe(
      tap(paramMap => {
        this.uiBlockerSrv.block();

        const id = paramMap.get('id') as string;

        if (typeof id !== 'undefined' && id !== null) {
          this.childSteps.loadSteps(id);
        } else {
          // Abrimos la ayuda del mapa
          this.helpRiskMap(true);
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  helpRiskMap(isOnInit: boolean) {
    const dialogRef = this.dialog.open(HelpRiskMapComponent, {
      height: '95%',
      width: '95%',
      maxHeight: '100vw !important',
      maxWidth: '100vw !important'
    });

    if (isOnInit) {
      this._subscriptions.sink = dialogRef.afterClosed().subscribe(() => {
        this.childSteps.loadSteps(null);
      });
    }
  }

  get newData() {
    return this.dataRiskMapSrv.canSaveMap();
  }

  saveMapData() {
    this.uiBlockerSrv.block();

    this.mapSrv.save(this.dataRiskMapSrv.getDataRiskMap(null)).subscribe((response) => {
      const mapId = response.result as number;
      this.dataRiskMapSrv.setMapId(mapId);
      this.dataRiskMapSrv.setAlreadySaved(true);
      this.uiBlockerSrv.unblock();
    });
  }

  downloadRiskReport() {
    this.dialog.open(DownloadReportComponent, {
      width: '1000px'
    });
  }
}

import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { IMap, IMapFilter } from 'src/app/core/models/api';

import { IStep } from 'src/app/core/models/front';

export abstract class IMapService {
  abstract getSteps(mapToLoad: string | null): Observable<Array<IStep>>;
  abstract getUnfinishedMap(): Observable<IMap>;
  abstract get(mapId: string): Observable<IMap>;
  abstract save(data: IMap): Observable<IResponse<number>>;
  abstract generateReport(data: IMap): Observable<Blob>;
  abstract search(request: IMapFilter): Observable<IPageOf<IMap>>;
  abstract downloadReport(mapId: string): Observable<Blob>;
}

import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IMap, IMapFilter } from 'src/app/core/models/api';
import { IStep } from 'src/app/core/models/front';
import { IMapService } from '.';

@Injectable({
  providedIn: 'root'
})
export class MapService implements IMapService {
  apiName = API_PATH_APPS.API_RISK_MAP;
  basePath = 'api/map';

  constructor(private httpClient: HowdenHttpClientService) { }

  getSteps(mapToLoad: string | null): Observable<Array<IStep>> {
    if (mapToLoad === null) {
      mapToLoad = '0';
    }
    return this.httpClient.get<Array<IStep>>(
      this.apiName,
      `${this.basePath}/steps/${mapToLoad}`
    );
  }

  getUnfinishedMap(): Observable<IMap> {
    return this.httpClient.get<IMap>(
      this.apiName,
      `${this.basePath}/unfinished`
    );
  }

  get(id: string): Observable<IMap> {
    return this.httpClient.get<IMap>(
      this.apiName,
      `${this.basePath}/${id}`
    );
  }

  save(data: IMap): Observable<IResponse<number>> {
    return this.httpClient.post<IResponse<number>>(
      this.apiName,
      this.basePath,
      data
    );
  }

  generateReport(data: IMap): Observable<Blob> {
    return this.httpClient.postRespBlob(
      this.apiName,
      `${this.basePath}/report`,
      data,
      { responseType: 'blob' }
    );
  }

  search(request: IMapFilter): Observable<IPageOf<IMap>> {
    return this.httpClient.post<IPageOf<IMap>>(
      this.apiName,
      `${this.basePath}/search`,
      request
    );
  }

  downloadReport(mapId: string): Observable<Blob> {
    return this.httpClient.getBlob(
      this.apiName,
      `${this.basePath}/download-report/${mapId}`,
      { responseType: 'blob' }
    );
  }
}
